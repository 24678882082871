nav {
  display: flex;
  align-items: center;
}

nav ul.nav-list {
  list-style-type: none;
  display: flex;
  align-items: center;
  padding: 0;
  font-family: var(--theme-font);
  transition: all 250ms ease-in-out;
}

nav ul.nav-list li a {
  color: var(--theme-color);
  font-size: 1rem;
  padding-inline: 1.75em;
  font-weight: 500;
  transition: all 100ms ease-in-out;
  cursor: pointer;
  text-decoration: none;
  letter-spacing: 1px;
}

nav ul.nav-list li a:hover {
  color: var(--dark);
}

.header-btn {
  margin-left: 4em;
}

.header-btn a {
  font-size: 14px;
}

.burger-active,
.burger-closed {
  width: 30px;
  height: 3px;
  background: var(--theme-color);
  display: none;
  position: relative;
  cursor: pointer;
  transition: all 150ms ease-in-out;
}

.burger-clicked:nth-child(2) {
  display: none;
}

.burger-clicked:first-child {
  rotate: 45deg;
  translate: 0 0;
}
.burger-clicked:last-child {
  rotate: -45deg;
  translate: 0 -3px;
}

.burger-active:nth-child(2) {
  margin: 5px 0;
}

nav ul.nav-list li a.active {
  color: var(--secondary-color);
  background: var(--theme-color);
  border-radius: 0.25rem;
  font-weight: 600;
}

a.active:hover {
  color: inherit;
}

@media (max-width: 1055px) {
  .header-btn a {
    font-size: 12px;
  }
}

@media (max-width: 853px) {
  header {
    position: relative;
  }

  .menu-hidden {
    transform: translateY(-100%);
    opacity: 0;
    visibility: hidden;
  }

  .burger-active {
    display: block;
  }

  nav ul.nav-list {
    position: absolute;
    justify-content: center;
    top: 2.5em;
    left: 0;
    right: 0;
    padding: 3.75em 2em;
    background-color: #eee;
    flex-direction: column;
    width: 100%;
    gap: 1em 0;
    z-index: -1;
  }

  .nav-list .header-btn {
    margin-left: 0;
  }
}
