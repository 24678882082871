@import url("https://fonts.googleapis.com/css2?family=Baloo+2:wght@400;500;600;700;800&family=Quicksand:wght@300;400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

@font-face {
  font-family: fa-brands;
  src: url(../src/fonts/fa-brands-400.ttf);
}

@font-face {
  font-family: fa-regular;
  src: url(../src/fonts/fa-regular-400.ttf);
}

@font-face {
  font-family: fa-solid;
  src: url(../src/fonts/fa-solid-900.ttf);
}

:root {
  --theme-color: hsl(1, 79%, 48%);
  --theme-color-dark: hsl(1, 79%, 28%);
  --theme-color-light: hsl(1, 79%, 68%);
  --theme-color-lighter: hsl(1, 79%, 78%);

  --accent-color: hsl(173, 58%, 39%);
  --accent-color-light: hsl(173, 58%, 59%);
  --accent-color-lighter: hsl(173, 58%, 79%);

  --dark: hsl(30, 13%, 6%);
  --yellow-light: hsl(30, 13%, 36%);
  --yellow-lighter: hsl(30, 13%, 66%);

  --btn-color: hsl(27, 87%, 67%);
  --btn-color-light: hsl(27, 87%, 77%);
  --btn-color-lighter: hsl(27, 87%, 87%);

  --secondary-color: hsl(42, 100%, 91%);
  --secondary-color-light: hsl(12, 76%, 71%);
  --secondary-color-lighter: hsl(12, 76%, 81%);

  --theme-font: Poppins, sans-serif;
  --heading-font: Quicksand, sans-serif;

  --fs-0: 14px;
  --fs-1: 16px;
  --fs-2: 26px;
  --fs-3: 36px;
  --fs-4: 42px;
  --fs-5: 68px;

  --section-padding: 6.5em 4em;
  --section-padding-mobile: 3.25em 2em;
}

*,
*::after,
*::before {
  box-sizing: border-box;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--heading-font);
  font-size: var(--fs-2);
  text-transform: uppercase;
  color: var(--dark);
}

a {
  text-decoration: none;
}

p,
span,
a,
.btn {
  font-family: var(--theme-font);
  color: var(--dark);
}

img {
  max-width: 100%;
}

.btn {
  display: flex;
  align-items: center;
  background: var(--theme-color);
  color: var(--secondary-color);
  border: none;
  border-radius: 0.25em;
  padding: 0.75em 2.25em;
  cursor: pointer;
}

.btn span {
  font-family: fa-solid;
  padding-left: 0.75em;
  transition: 100ms ease;
  color: var(--secondary-color);
}

.btn:hover {
  background: var(--yellow-light);
}

.btn:hover span {
  transform: translateX(10px);
}
