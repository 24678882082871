.services {
  padding: var(--section-padding);
}

.services-inner {
  max-width: 1200px;
  margin: 0 auto;
}

.services-content {
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  gap: 1.5em;
}

.services-inner h1 {
  color: var(--theme-color-dark);
  font-size: var(--fs-3);
  text-align: center;
}

.services-inner .service-box {
  background: var(--theme-color);
  padding: 1em 1.618em;
  padding: var(--section-padding-mobile);
  margin: 1em auto;
  border-radius: 0.45em;
  color: white;
  max-width: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.services-inner > p {
  text-align: center;
}

.services-inner .service-box h2,
.services-inner .service-box p {
  color: white;
  text-align: center;
}

.price {
  font-size: var(--fs-2);
  color: var(--theme-color-dark);
  border-radius: 0.25em;
  background: rgb(255, 255, 255);
  padding: 0.25em 1.618em;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 853px) {
  .services {
    padding: 4em 0;
  }

  .services-inner {
    padding: var(--section-padding-mobile);
  }

  .services-content {
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  }

  .services-inner .service-box {
    padding: 1.618rem 0.5rem;
  }
}
