main .main-inner,
.home-sec2_cards,
.home-sec4_inner {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 0 2.5rem;
  padding: var(--section-padding);
}

/** SECTION 1 **/

.main-inner_img,
.main-inner_content {
  flex: 1 1 300px;
}

.main-inner_img img {
  display: block;
  min-height: 500px;
  object-fit: cover;
}

/** HOT ASPHALT **/
.hot-asphalt {
  background-image: url(../images/asphalt.png);
  background-color: #efefef;
  background-blend-mode: multiply;
  background-position-x: -200px;
  background-repeat: no-repeat;
  padding: var(--section-padding-mobile);
}

.temps-container {
  max-width: 650px;
  margin: 0 auto;
  background: #ffffffbd;
  padding: 1.618em;
  box-shadow: 1px 1px 14px rgba(0, 0, 0, 0.082);
  border-radius: 0.25rem;
}

.hot-asphalt h2 {
  text-align: center;
  font-size: var(--fs-5);
  margin-block: 0;
}

.temp-titles {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.temp-title {
  font-weight: bold;
  color: var(--theme-color);
  text-align: center;
}

.temps {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.temp-nums {
  text-align: center;
  padding: 0 0.5em;
  font-size: 1.5rem;
  flex: 1;
}

.temp-nums:nth-child(2) {
  align-self: flex-end;
  margin-bottom: 5px;
}

.temp-nums:nth-child(2) p {
  letter-spacing: 0.5vw;
}

.hot-asphalt-info p,
.hot-asphalt-info strong {
  text-align: center;
}

@media (max-width: 768px) {
  .hot-asphalt,
  .temps-container {
    padding: 2em 0;
    background-position: bottom left;
    background-size: contain;
  }

  .temp-title {
    font-size: 1rem;
  }

  .temp-nums p {
    font-size: 1.25rem;
    font-weight: 500;
  }
  .hot-asphalt h2 {
    font-size: var(--fs-4);
  }
}

/** SECTION 2 **/

.home-sec2_cards {
  align-items: stretch;
}

.home-sec2_cards {
  max-width: 1500px;
  margin: 0 auto;
}

.home-sec2_card {
  padding: var(--section-padding);
  border-radius: 0.25rem;
  min-width: 300px;
  flex: 1 1 300px;
  margin: 1em 0;
  gap: 0;
}

.home-sec2_card img {
  max-width: 100px;
}

.home-sec2_card:first-child {
  background: var(--theme-color);
  color: var(--secondary-color);
  border: 1px solid var(--secondary-color);
}

.home-sec2_card:first-child h2 {
  color: var(--secondary-color);
}

.home-sec2_card:first-child p {
  color: var(--secondary-color);
}

.home-sec2_card:first-child img,
.home-sec2_card:nth-child(2) img {
  filter: invert(85%);
}

.home-sec2_card:nth-child(2) {
  background: var(--dark);
  color: var(--secondary-color);
}

.home-sec2_card:nth-child(2) h2 {
  color: var(--secondary-color);
}

.home-sec2_card:nth-child(2) p {
  color: var(--secondary-color);
}

.home-sec2_card:last-child {
  background: var(--secondary-color);
  color: var(--theme-color);
}

.home-sec2_card:last-child h2 {
  color: var(--theme-color);
}

.home-sec2_card:last-child p {
  color: var(--theme-color);
}

.home-sec2_card:first-child,
.home-sec2_card:last-child {
  max-height: 600px;
}

@media (max-width: 1330px) {
  .home-sec2_card {
    padding: var(--section-padding-mobile);
    border-radius: 0.25rem;
    min-width: 300px;
    flex: 1 1 300px;
    margin: 1em 0;
    gap: 0;
  }

  .home-sec2_card:first-child,
  .home-sec2_card:last-child {
    max-height: initial;
  }
}

/** SECTION 3 **/
.home-sec3 {
  padding: var(--section-padding);
  background: var(--theme-color);
}

.home-sec3_inner {
  max-width: 900px;
  margin: 0 auto;
}

.home-sec3_inner h3,
.home-sec3_inner p {
  text-align: center;
  color: var(--secondary-color);
}

/** SECTION 4 **/

.home-sec4_inner-content,
.home-sec4_inner-img {
  width: 50%;
  flex: 1 1 300px;
  min-width: 300px;
}

.home-sec4_inner-img img {
  min-width: 100%;
}

@media (max-width: 1440px) {
  .main {
    margin-top: 10em;
  }
}

@media (max-width: 915px) {
  .main {
    margin-top: 2em;
  }

  .main .main-inner,
  .home-sec2_cards,
  .home-sec3,
  .home-sec4_inner {
    padding: var(--section-padding-mobile);
  }
}

@media (max-width: 853px) {
  .home-sec4_inner {
    flex-direction: column-reverse;
  }
  .home-sec4_inner-content,
  .home-sec4_inner-img {
    width: 100%;
  }
}
