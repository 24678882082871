header {
  position: fixed;
  width: 100%;
  z-index: 10000;
  transition: all 250ms ease-in-out;
  padding: 0 3em;
  background-color: white;
  box-shadow: 0 0 5px black;
}

header::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 1px;
  background: var(--theme-color);
  transform: translateX(-100%);
  transition: all 450ms ease-in-out;
}

header.scrolled::after {
  transform: translateX(0);
}

.header-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
}

header img {
  width: 65px;
  padding: 0.25em;
  display: block;
}

@media (max-width: 1150px) {
  .header-btn {
    margin-left: 2em;
  }
  .header-wrapper {
    padding: 0 2em;
  }
  nav ul li a {
    padding-inline: 1em;
    font-size: 1.1rem;
  }
  header img {
    width: 260px;
  }
}

@media (max-width: 1030px) {
  header {
    padding: 0 1em;
  }
  header img {
    width: 40px;
  }
  nav ul li a {
    padding-inline: 1em;
    font-size: 0.95rem;
  }
}

@media (max-width: 853px) {
  header {
    padding: 0.5em;
    top: 0;
  }
}
