.reviews {
  transition: all 250ms ease;
  max-width: 1200px;
  margin: 3em auto;
  padding: 5em;
  border: 1px solid black;
}

.reviews-inner h1 {
  text-align: center;
}

@media (max-width: 1300px) {
  .reviews {
    margin-top: 10em;
  }
}
