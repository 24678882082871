footer {
  background: var(--theme-color);
}

.footer-inner {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 5rem 2.5rem;
}

.footer-inner > * {
  flex: 1;
  min-width: 300px;
  margin: 0 1em;
}

.footer-inner_left img {
  max-width: 120px;
}

.footer-inner_left p {
  color: var(--yellow);
}

.footer-inner_left a {
  text-decoration: none;
  font-size: var(--fs-2);
  color: white;
  font-weight: bold;
}

.footer-inner_left a:hover {
  color: white;
}

.footer-inner_left-sm-links {
  margin: 2.5em 0 0 0;
}

.footer-inner_left-sm-links span {
  font-family: fa-brands;
  font-size: 1.65rem;
  margin-right: 0.65em;
  color: white;
}

.footer-inner_mid h3 {
  color: white;
}

.footer-inner_mid ul {
  color: white;
  list-style: none;
  padding: 0 0.75em;
}

.footer-inner_mid ul li::marker {
  content: "\f101";
  font-family: fa-solid;
  color: white;
  font-size: 0.75rem;
}

.footer-inner_mid ul li {
  margin: 0.65rem 0;
  padding-left: 1em;
}

.footer-right img {
  width: 340px;
}

.copyright {
  background: var(--yellow);
  padding: 5px;
  text-align: center;
  color: var(--theme-color-dark);
}

.copyright p {
  color: var(--theme-color-dark);
}
