.gallery {
  margin-top: 5em;
}

.gallery-inner {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(340px, 1fr));
  gap: 1.5em;
  padding: var(--section-padding-mobile);
}

.gallery-inner > * {
  border-radius: 5px;
}

@media (max-width: 768px) {
  .gallery-inner {
    grid-template-columns: repeat(auto-fit, minmax(140px, 1fr));
  }
}
