.hero {
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
  transform: translateY(75px);
  padding: var(--section-padding);
}

.hero img {
  width: 675px;
  max-width: 100%;
}

.hero-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  padding: 6.5em 4em;
}

.hero_content {
  flex: 1;
  max-width: 741px;
  min-width: 600px;
}

.hero_content-title {
  font-size: var(--fs-4);
  font-family: var(--heading-font);
  font-weight: 700;
  color: var(--theme-color);
  text-transform: capitalize;
}

.hero_content-subtitle {
  display: block;
  font-size: var(--fs-1);
}

.hero_content .btn {
  margin-top: 3em;
}

@media (max-width: 1440px) {
  .hero-container {
    transform: translateY(60px);
  }

  .hero_content {
    max-width: 50%;
    min-width: unset;
  }

  .hero_content-title {
    font-size: var(--fs-3);
    font-family: var(--heading-font);
    font-weight: 700;
    color: var(--secondary-color);
  }
}
@media (max-width: 1130px) {
  .hero-container {
    transform: translateY(10px);
  }

  .hero_content {
    max-width: 50%;
    min-width: unset;
  }

  .hero_content-title {
    font-size: var(--fs-3);
    font-family: var(--heading-font);
    font-weight: 700;
    color: var(--yellow);
  }
}

@media (max-width: 980px) {
  .hero-container {
    transform: translateY(-20px);
  }
}

@media (max-width: 915px) {
  .hero-container {
    flex-direction: column;
    transform: translateY(0px);
  }
  .hero_content {
    width: 100%;
    min-width: unset;
    max-width: 100%;
  }

  .hero_content-subtitle {
    display: block;
    font-size: var(--fs-1);
  }
}

@media (max-width: 853px) {
  .hero {
    position: relative;
    flex-direction: column;
    padding: 0;
  }

  .hero-container {
    padding: 2em;
  }
}
